<template>
  <div>
    <div v-if="!this.userRole">
      <q-skeleton height="50px" style="margin-top: 10px" />
      <q-skeleton height="80px" style="margin-top: 10px" />
    </div>
    <div>
      <pro-tender-form-render
        mode="Edit"
        code="P_T_RecTendPtn"
        :tenderId="$route.params.id"
        :form-data-promise="formDataPromise()"
        @pro-form-submit="submittedEvent()"
      />
    </div>
  </div>
</template>

<script>
import ProTenderFormRender from "@/components/PROSmart/FormBuilder/ProTenderFormRender";
export default {
  name: "PtnOpening",
  components: { ProTenderFormRender },
  data() {
    return {
      userRole: "",
      info: {
        ptnId: this.$route.params.ptnId,
      },
    };
  },
  methods: {
    formDataPromise() {
      return new Promise((resolve) => {
        let obj = this.info;
        obj.tenderId = this.$route.params.id;
        resolve(obj);
      });
    },
    submittedEvent() {
      sessionStorage.removeItem("todoItem");
      this.$router.push({ name: "TodoList" });
    },
  },
  async created() {
    this.userRole = this.$route.params.userRole;
    if (this.userRole === null) {
      let rs = await this.$proSmart.documentUi.createAndCheckHcmGroupForOpening(
        this,
        this.$route.params.id
      );

      this.userRole = rs.keyName;
    }
  },
};
</script>
